import {useMemo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import _ from "lodash";
import {
    ScolariteProformaEnfant,
    scolariteProformaEnfantDataImpl
} from "../../../../constants/type/ScolariteProformaEnfant";
import {Child} from "../../../../constants/type/Child";
import {Profils} from "../../../../constants/type/Profils";
import {ScolariteType} from "../../../../constants/type/ScolariteType";
import {ScolariteProforma, scolariteProformaDataImpl} from "../../../../constants/type/ScolariteProforma";
import {ScolariteGroup} from "../../../../constants/type/ScolariteGroup";
import {ScolariteAnnee} from "../../../../constants/type/ScolariteAnnee";
import {GroupEnfant} from "../../../../constants/type/GroupEnfant";
import {Groupesection} from "../../../../constants/type/Groupesection";
import {useMyMaterialReactTable} from "../../../../components/ui/materialReactTable/MyMaterialReactTableEvents";
import {
    getMyReactTableDefaultOptions
} from "../../../../components/ui/materialReactTable/MyDefaultMaterialReactTableOptions";

const useScolariteProformaEnfantVariables = (props: any): any => {
    const {snackbarShowMessage, theme, styleClasses} = props;

    const dispatch = useDispatch();
    const dataCaches = useSelector((state: any) => state.cacheDB.dataCaches);
    const userRef = useRef<any>(useSelector((state:any) => state.user.user));
    const activeLang = props.locale.split('-')[0];

    const mytableState = useMyMaterialReactTable({tableId:'ScolariteProformaEnfant', updateId:2}) ;
    const logicMRT = useMemo<any>(() => getMyReactTableDefaultOptions<ScolariteProformaEnfant>(mytableState), []);
    const tableConfigRef = useRef<any>({getState:()=>{}});

    const [openEditDialogForm, setOpenEditDialogForm] = useState<boolean>(false);
    const [openEditDetailsDialogForm, setOpenEditDetailsDialogForm] = useState<boolean>(false);
    const [openDeleteDialogForm, setOpenDeleteDialogForm] = useState<boolean>(false);
    const [openPDFDialogForm, setOpenPDFDialogForm] = useState<boolean>(false);
    const [processingStateNormBtn, setProcessingStateNormBtn] = useState<boolean>(false);
    const [processingSubmitStateNormBtn, setProcessingSubmitStateNormBtn] = useState<boolean>(false);
    const [processingLoadingStateNormBtn, setProcessingLoadingStateNormBtn] = useState<boolean>(false);
    const [tableKeyValue, setTableKeyValue] = useState(new Date().getTime());
    const [tableDetailsKeyValue, setTableDetailsKeyValue] = useState(new Date().getTime());


    const scolariteTypeMapRef = useRef<Map<number, ScolariteType>>(new Map());
    const scolariteTypeRef = useRef<ScolariteType[]>([]);
    const profilsRef  = useRef<Profils[]>([]);
    const profilsMapRef  = useRef<Map<number, Profils>>(new Map());
    const childrenRef = useRef<Child[]>([]);
    const childrenMapRef  = useRef<Map<number, Child>>(new Map());
    const childrenClasseRef = useRef<Child[]>([]);
    const editDisableRef = useRef<boolean>(true);
    const scolariteGroupsRef = useRef<ScolariteGroup[]>([]);
    const scolariteGroupsMapRef = useRef<Map<number, ScolariteGroup>>(new Map());
    const scolariteFraisGroupOptionalRef = useRef<ScolariteGroup[]>([]);
    const scolariteAnneesRef = useRef<ScolariteAnnee[]>([]);
    const groupEnfantsRef = useRef<GroupEnfant[]>([]);
    const groupesectionsRef = useRef<Groupesection[]>([]);
    const groupesectionMapRef  = useRef<Map<number, Groupesection>>(new Map());


    const [dataList, setDataList] = useState<ScolariteProformaEnfant[]>([]);
    const [dataListSelected, setDataListSelected] = useState<ScolariteProformaEnfant[]>([]);
    const [proformaDataList, setProformaDataList] = useState<ScolariteProforma[]>([]);
    const [proformaEnfantSel, setProformaEnfantSel] = useState<ScolariteProformaEnfant>(_.cloneDeep(scolariteProformaEnfantDataImpl));
    const [dataSel, setDataSel] = useState<ScolariteProforma>(_.cloneDeep(scolariteProformaDataImpl));
    const [tableDataLoading, seTableDataLoading] = useState<boolean>(true);
    const [contentLoaded, setContentLoaded] = useState<boolean>(false);
    const [showDetailsTableContent, setShowDetailsTableContent] = useState<boolean>(true);   
    const [idScolariteAnneeSel, setIdScolariteAnneeSel] = useState<number[]>([]);


    return {
        snackbarShowMessage,theme,styleClasses,userRef,activeLang,openEditDialogForm,setOpenEditDialogForm,
        openDeleteDialogForm, setOpenDeleteDialogForm,processingStateNormBtn,setProcessingStateNormBtn,
        dataList,setDataList,dataListSelected,setDataListSelected,dataSel,setDataSel,proformaEnfantSel,setProformaEnfantSel,
        tableKeyValue,setTableKeyValue,tableDataLoading,seTableDataLoading,openEditDetailsDialogForm,
        setOpenEditDetailsDialogForm,editDisableRef, contentLoaded,setContentLoaded,processingSubmitStateNormBtn,setProcessingSubmitStateNormBtn, childrenClasseRef,scolariteTypeMapRef,scolariteTypeRef,profilsRef,
        childrenRef,proformaDataList,setProformaDataList,childrenMapRef,profilsMapRef,
        showDetailsTableContent,setShowDetailsTableContent,tableDetailsKeyValue,setTableDetailsKeyValue,
        processingLoadingStateNormBtn,setProcessingLoadingStateNormBtn,
        openPDFDialogForm,setOpenPDFDialogForm,dataCaches, dispatch,
        idScolariteAnneeSel,setIdScolariteAnneeSel, scolariteGroupsRef, scolariteGroupsMapRef, scolariteFraisGroupOptionalRef, scolariteAnneesRef, groupEnfantsRef, groupesectionsRef, groupesectionMapRef,
        tableConfigRef,
        ...logicMRT
    }
}
export default useScolariteProformaEnfantVariables;